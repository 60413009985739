<template>
  <b-row>
    <b-col
      cols="12"
      md="3"
    >
      <b-form-group
        label="Marka"
        label-for="id_brands"
      >
        <v-select
          v-model="search.id_brands"
          :options="brands"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
          @input="searchData"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="9"
    >
      <b-form-group
        label="Firma Adı"
        label-for="keyword"
      >
        <b-input-group>
          <b-form-input
            v-model="search.keyword"
            placeholder="Firma Adı..."
            @keydown.enter="searchData"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchData"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BFormInput, BButton, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Search',
  components: {
    BFormGroup,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BRow,
    BCol,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    search() {
      return this.$store.getters['services/search']
    },
    brands() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData.brands
    },
  },
}
</script>
